import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 909.000000 1035.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1035.000000) scale(0.100000,-0.100000)">
        <path d="M4971 8766 c2 -2 20 -11 39 -20 l35 -17 -24 21 c-13 11 -30 20 -39
20 -8 0 -13 -2 -11 -4z"/>
<path d="M5117 8699 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M5230 8646 c0 -6 95 -66 105 -66 4 0 -8 11 -28 24 -44 30 -77 48 -77
42z"/>
<path d="M5420 8530 c35 -24 54 -31 30 -11 -14 11 -29 21 -35 21 -5 0 -3 -5 5
-10z"/>
<path d="M5490 8486 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M5645 8380 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6316 8336 c4 -11 64 -39 70 -33 2 1 -15 12 -36 23 -21 12 -36 16
-34 10z"/>
<path d="M5805 8260 c16 -16 33 -30 39 -30 6 0 -4 14 -22 30 -18 17 -36 30
-39 30 -4 0 6 -13 22 -30z"/>
<path d="M6533 8210 c14 -11 31 -20 39 -20 7 0 -2 9 -20 20 -42 26 -52 26 -19
0z"/>
<path d="M5885 8188 c25 -14 279 -269 358 -360 93 -106 268 -337 407 -538 175
-252 332 -532 443 -790 50 -116 153 -415 162 -470 3 -19 13 -55 21 -80 34 -99
47 -158 47 -225 0 -41 3 -62 7 -50 12 31 24 -46 17 -108 -3 -29 -2 -43 2 -32
11 27 19 -32 31 -214 10 -159 11 -552 1 -461 l-7 55 -8 -65 c-4 -36 -6 -114
-4 -175 2 -87 0 -109 -10 -107 -10 2 -12 -4 -7 -20 8 -26 8 -23 5 -63 -2 -29
-2 -29 -9 -5 -7 24 -7 24 -14 -9 -4 -18 -7 -43 -6 -55 0 -21 1 -21 10 -1 7 15
8 9 4 -20 -4 -22 -9 -59 -12 -82 -3 -24 -9 -40 -14 -37 -5 3 -6 -1 -4 -8 3 -7
-6 -56 -20 -108 -14 -52 -28 -108 -32 -124 -3 -15 -9 -23 -12 -17 -4 6 -17
-19 -29 -56 -16 -47 -19 -68 -11 -73 7 -5 8 -14 1 -26 -12 -24 -53 -142 -59
-171 -3 -13 -9 -23 -14 -23 -5 0 -9 -8 -9 -18 0 -10 -4 -22 -8 -27 -5 -6 -15
-26 -22 -45 -17 -45 -144 -304 -171 -346 -14 -23 -25 -31 -37 -28 -13 5 -14 4
-3 -4 18 -13 -2 -50 -35 -62 -13 -5 -24 -15 -24 -21 0 -8 6 -7 17 2 17 13 17
12 0 -11 -10 -14 -24 -39 -32 -57 -8 -17 -20 -37 -27 -45 -7 -8 -18 -23 -25
-33 -6 -11 -16 -19 -23 -17 -6 1 -8 -3 -4 -9 3 -6 3 -14 -2 -18 -5 -3 -45 -53
-89 -111 -71 -94 -138 -169 -261 -295 -108 -110 -371 -324 -499 -406 -38 -25
-96 -62 -128 -83 -32 -21 -134 -75 -225 -121 -470 -232 -966 -339 -1502 -322
-412 13 -779 92 -1174 253 -120 49 -321 149 -349 172 -9 8 -20 12 -24 9 -3 -4
-1 -7 6 -7 8 0 10 -7 6 -19 -3 -12 0 -21 10 -25 9 -3 13 -10 10 -16 -4 -6 1
-7 10 -3 13 5 14 7 3 14 -16 10 2 12 27 3 13 -5 14 -8 3 -15 -10 -7 -9 -9 4
-9 12 0 15 -5 11 -17 -5 -12 -2 -15 10 -10 11 4 17 1 17 -8 0 -8 4 -15 9 -15
5 0 32 -25 62 -55 29 -30 57 -55 61 -55 4 0 17 -9 28 -20 11 -11 27 -20 35
-20 8 0 15 -9 15 -20 0 -13 5 -18 15 -14 9 4 19 -3 26 -19 7 -17 21 -27 38
-29 18 -2 26 -9 25 -21 -1 -12 5 -17 22 -17 13 0 24 -5 24 -11 0 -5 4 -8 9 -5
5 3 11 -1 15 -9 3 -8 17 -15 31 -15 14 0 28 -7 31 -15 4 -8 17 -15 31 -15 13
0 22 -4 19 -9 -6 -9 16 -19 93 -42 24 -7 49 -19 57 -26 7 -7 19 -13 28 -13 8
0 27 -11 42 -25 15 -14 34 -25 43 -25 22 -1 60 -23 60 -35 0 -5 4 -11 10 -13
6 -2 11 2 11 10 0 8 4 7 11 -5 5 -9 20 -17 32 -17 13 0 28 -5 35 -12 9 -9 12
-9 12 1 0 10 3 11 13 3 7 -6 28 -13 45 -14 21 -1 38 -11 48 -26 11 -16 22 -22
35 -19 12 3 30 -4 46 -19 14 -13 34 -24 44 -24 10 0 20 -4 23 -9 3 -4 34 -14
68 -21 35 -7 75 -21 90 -31 15 -11 36 -19 48 -19 11 0 20 -3 20 -7 0 -5 21 -9
47 -11 26 -1 50 -7 53 -12 4 -6 29 -11 56 -11 27 -1 67 -9 89 -19 46 -20 109
-24 136 -9 15 7 19 6 19 -6 0 -10 10 -15 34 -15 19 0 38 -5 41 -11 4 -6 13 -8
21 -5 8 3 41 -1 74 -10 33 -8 64 -13 69 -10 5 4 22 1 38 -5 15 -7 54 -11 86
-10 31 1 67 -4 79 -10 13 -7 37 -12 55 -11 18 1 33 -3 33 -8 0 -14 54 -12 68
2 7 7 16 8 24 2 14 -10 52 -9 127 2 31 4 46 3 51 -7 6 -9 12 -10 22 -2 15 11
157 11 177 -1 6 -4 17 -3 24 2 7 5 31 13 55 17 23 4 42 11 42 17 0 6 7 5 16
-3 13 -10 20 -10 45 5 24 14 33 15 45 5 11 -9 18 -8 32 5 13 11 37 16 76 16
38 0 63 5 72 15 18 17 34 20 34 5 0 -6 8 -5 20 2 16 10 24 10 40 0 18 -11 20
-10 21 10 0 13 3 17 6 11 7 -18 33 -16 33 2 0 21 28 19 33 -3 4 -13 5 -14 6
-1 1 16 81 61 103 58 34 -4 49 2 46 19 -2 12 3 16 17 15 98 -12 95 -13 95 13
0 26 10 30 27 13 9 -9 20 -5 44 11 17 13 45 23 60 23 16 0 29 4 29 9 0 4 -4 8
-10 8 -5 0 -10 5 -10 10 0 6 7 6 19 -2 15 -9 23 -7 42 10 12 12 29 22 37 22 7
0 20 7 28 15 18 17 34 20 34 4 0 -5 5 -7 10 -4 6 4 8 10 5 15 -3 5 10 12 30
16 36 6 45 18 23 27 -7 3 1 6 19 6 22 1 39 10 56 31 14 16 31 30 37 30 6 0 13
6 16 14 3 8 20 17 39 20 18 4 38 13 45 21 7 8 19 15 26 15 8 0 14 5 14 10 0 6
10 10 23 11 21 1 21 1 -3 9 -17 5 -19 8 -7 9 24 1 54 20 94 59 19 17 39 32 44
32 5 0 7 11 3 24 -5 21 -3 23 19 19 27 -5 111 33 102 47 -8 13 16 25 43 21 23
-3 31 14 10 22 -19 6 9 37 33 37 14 0 21 9 26 31 4 21 9 27 16 20 21 -21 34
-12 29 19 -3 23 0 30 12 30 9 0 21 11 28 24 6 16 21 26 37 28 20 2 27 9 29 31
3 21 8 27 28 27 18 0 25 5 24 18 -4 38 12 62 41 62 31 0 41 23 14 34 -13 4
-14 10 -5 26 11 21 40 28 40 10 0 -5 4 -10 10 -10 5 0 9 10 7 22 -1 14 3 22
11 21 6 -2 12 6 12 17 0 11 5 20 10 20 6 0 23 20 39 45 15 24 31 42 33 39 3
-3 8 2 11 11 4 8 2 15 -3 15 -6 0 -10 7 -10 15 0 8 9 15 20 15 10 0 24 9 30
20 6 11 17 20 25 20 8 0 15 5 15 10 0 6 -7 10 -15 10 -18 0 -18 2 -3 35 6 14
18 25 27 25 11 0 10 -3 -5 -14 -22 -16 -15 -30 10 -20 9 3 16 16 16 28 0 11
16 38 35 59 19 21 35 42 35 46 0 4 9 16 20 26 11 10 20 26 20 35 0 9 9 25 20
35 11 10 23 33 26 52 4 20 17 54 30 77 13 24 25 58 27 76 2 18 10 38 18 44 21
17 39 53 39 76 0 12 5 18 10 15 6 -3 11 0 12 7 3 29 5 34 20 68 9 19 19 53 23
75 4 22 18 54 31 72 13 17 24 40 24 50 0 10 7 30 14 46 8 15 11 27 6 27 -5 0
0 21 12 46 11 26 20 62 21 80 1 19 6 34 13 34 6 0 17 6 23 14 9 11 8 15 -5 20
-13 5 -14 9 -4 21 7 8 9 15 6 15 -4 0 0 7 8 16 9 8 16 29 16 46 0 16 8 41 19
54 11 14 19 41 19 69 1 24 8 56 17 69 10 14 17 50 19 86 1 41 7 64 16 70 12 8
10 10 -7 10 -20 0 -22 4 -16 33 3 17 10 34 15 35 4 2 5 8 2 14 -3 5 2 15 12
23 15 11 16 15 5 28 -11 15 -10 101 2 114 3 2 1 18 -3 34 -5 17 -5 31 1 35 5
3 7 21 3 40 -3 19 -2 40 4 47 6 7 11 35 10 62 0 55 9 150 17 177 3 9 1 19 -5
23 -6 3 -8 10 -5 15 3 5 1 20 -5 35 -7 19 -7 31 1 41 6 7 7 19 3 26 -5 7 -9
31 -11 53 -1 22 -2 40 -3 40 -1 0 -3 15 -5 33 -2 19 2 32 9 32 6 0 5 4 -3 9
-9 6 -11 18 -7 36 6 23 -5 136 -20 206 -2 13 -12 78 -20 144 -8 66 -24 149
-34 185 -28 97 -61 261 -54 272 4 6 2 13 -4 17 -8 5 -62 161 -134 391 -33 105
-146 366 -216 500 -224 426 -531 813 -892 1122 -63 54 -119 98 -125 98 -5 0 6
-15 25 -32 19 -17 69 -60 110 -97 96 -85 206 -196 213 -215 4 -9 13 -16 20
-16 8 0 22 -10 33 -22 19 -21 19 -21 -1 -15 -18 7 -19 6 -3 -6 10 -8 15 -17
11 -20 -3 -4 1 -7 11 -7 9 0 19 -7 22 -15 4 -8 13 -15 21 -15 8 0 13 -5 11
-12 -3 -7 8 -23 24 -36 15 -13 37 -41 49 -62 12 -22 26 -37 31 -34 4 3 8 -2 8
-10 0 -8 12 -24 26 -35 14 -11 23 -25 19 -31 -3 -5 1 -10 10 -10 9 0 14 -4 11
-9 -3 -5 1 -12 10 -15 8 -3 13 -10 10 -14 -3 -5 4 -16 15 -26 13 -10 19 -25
16 -36 -3 -13 0 -17 9 -13 8 3 17 -3 21 -13 4 -11 14 -24 22 -30 13 -10 13
-14 1 -29 -14 -16 -13 -17 8 -11 19 6 23 3 28 -23 4 -17 10 -28 15 -25 5 3 6
-3 2 -12 -5 -13 -3 -16 10 -11 12 5 17 1 17 -13 0 -19 36 -91 50 -100 4 -3 2
-11 -6 -18 -12 -11 -14 -8 -15 15 -1 24 -2 22 -10 -12 -5 -21 -7 -45 -5 -52 6
-15 -6 -17 -20 -3 -5 5 -26 10 -48 12 -21 1 -40 8 -43 15 -3 7 -13 13 -23 13
-11 0 -22 5 -25 10 -4 6 -11 8 -16 4 -11 -6 -39 13 -39 27 0 5 9 9 20 9 13 0
20 7 20 18 0 21 -18 42 -37 42 -7 0 -22 9 -33 20 -11 11 -27 20 -35 20 -8 0
-15 5 -15 10 0 6 7 10 15 10 25 0 17 17 -10 22 -18 4 -25 1 -25 -10 0 -12 -3
-12 -15 -2 -9 7 -13 20 -10 28 5 15 -30 62 -47 62 -4 0 -8 -9 -8 -20 0 -11 -4
-20 -8 -20 -12 0 -53 45 -51 56 5 29 -3 34 -48 31 l-48 -2 2 32 c2 17 -2 35
-7 38 -6 3 -10 1 -10 -4 0 -6 -4 -11 -9 -11 -14 0 -101 81 -101 94 -1 11 -45
66 -54 66 -2 0 -25 19 -49 43 -137 129 -177 163 -177 148 0 -6 26 -38 58 -71
139 -147 242 -275 242 -303 0 -9 5 -19 10 -22 6 -3 10 -14 10 -23 -1 -16 -1
-16 -11 1 -5 9 -16 17 -24 17 -8 0 -18 8 -24 18 -5 9 -13 17 -18 17 -4 0 -7 7
-6 16 2 11 -12 22 -37 32 -25 9 -40 22 -40 32 0 11 -5 15 -15 11 -8 -3 -15 -1
-15 4 0 5 -9 12 -21 15 -16 6 -18 9 -8 22 10 12 10 16 -1 23 -9 6 -17 4 -22
-3 -9 -14 -78 56 -78 79 0 10 -6 13 -18 10 -13 -4 -29 5 -53 31 -19 21 -65 65
-102 97 -37 33 -67 65 -67 72 0 6 -7 14 -15 18 -8 3 -15 13 -15 22 0 12 -7 15
-31 12 -22 -3 -33 0 -39 13 -11 20 -70 52 -70 38 0 -6 -5 -7 -11 -4 -7 5 -4
14 7 26 17 18 16 19 -24 19 -22 0 -44 5 -47 11 -4 6 -13 8 -21 5 -17 -7 -18 6
-1 23 10 11 8 12 -8 3 -18 -9 -23 -6 -34 19 -7 16 -19 29 -27 29 -17 0 -19 20
-1 21 6 0 -2 5 -20 10 -17 5 -35 18 -38 29 -3 11 -9 19 -13 17 -11 -3 -52 36
-52 50 0 7 -6 12 -12 11 -7 -2 -12 2 -11 9 2 6 -6 14 -16 18 -11 3 -34 23 -53
43 -18 21 -48 48 -66 61 -18 12 -30 26 -28 29 2 4 -8 8 -22 9 -24 1 -24 0 -7
-9z m1396 -1074 c-3 -43 -4 -44 -15 -22 -7 12 -9 25 -6 28 4 3 9 13 13 23 10
27 12 20 8 -29z m-121 21 c0 -16 -27 -32 -37 -21 -7 7 16 36 28 36 5 0 9 -7 9
-15z m44 -50 c7 -20 -4 -47 -17 -42 -19 6 -19 27 0 27 17 1 17 1 -1 15 -16 12
-17 15 -4 15 9 0 18 -7 22 -15z m-51 -12 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
14 0 19 -2 13 -5z m77 -32 c0 -5 -7 -14 -15 -21 -12 -10 -15 -10 -15 2 0 8 3
18 7 21 9 10 23 9 23 -2z m30 -41 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z m147 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-113 -40 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z
m143 -6 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-108 -58 c5
-16 6 -30 2 -30 -5 0 -11 8 -15 18 -3 9 -9 23 -12 30 -3 6 -1 12 4 12 6 0 15
-13 21 -30z m492 -32 c11 -20 10 -21 -3 -11 -20 17 -31 5 -13 -14 8 -8 15 -23
15 -33 0 -13 5 -17 15 -14 11 5 17 -3 21 -25 4 -17 11 -34 15 -37 5 -3 9 -15
9 -27 0 -20 0 -20 -15 -1 -8 10 -15 31 -15 46 0 21 -4 25 -20 21 -14 -4 -20 0
-20 11 0 9 -4 16 -9 16 -9 0 -44 61 -39 65 5 4 43 24 45 25 1 0 8 -10 14 -22z
m-101 -79 c0 -12 -7 -19 -20 -19 -14 0 -20 7 -20 21 0 11 5 17 10 14 6 -3 10
-1 10 5 0 6 5 8 10 5 6 -3 10 -15 10 -26z m287 -391 c-3 -7 -5 -2 -5 12 0 14
2 19 5 13 2 -7 2 -19 0 -25z m-630 -1655 c-2 -16 -4 -5 -4 22 0 28 2 40 4 28
2 -13 2 -35 0 -50z m-160 -815 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19
0 -25z m-4707 -1918 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6
0 11 -4 11 -10z m1830 -870 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z"/>
<path d="M6596 8165 c10 -8 24 -15 31 -15 6 0 13 -3 15 -7 5 -13 68 -54 68
-45 0 10 -106 82 -122 82 -7 0 -3 -7 8 -15z"/>
<path d="M6750 8060 c19 -16 41 -30 48 -30 7 0 10 -5 6 -12 -4 -6 -3 -8 3 -5
5 3 22 -5 38 -19 27 -23 52 -33 41 -15 -8 12 -153 111 -163 111 -4 0 8 -14 27
-30z"/>
<path d="M4345 7939 c-375 -20 -765 -90 -1114 -200 -225 -71 -555 -220 -742
-335 -52 -31 -116 -70 -143 -86 -199 -118 -492 -368 -662 -564 -79 -91 -224
-294 -224 -313 0 -6 -4 -11 -8 -11 -5 0 -24 -30 -43 -67 -19 -38 -37 -70 -40
-73 -14 -13 -79 -152 -75 -159 3 -5 -1 -26 -8 -47 -10 -26 -15 -32 -16 -19 -1
20 -1 20 -9 0 -9 -23 -3 -55 10 -55 11 0 1 -50 -13 -65 -6 -6 -7 -4 -3 8 4 10
2 17 -4 17 -8 0 -10 -11 -6 -32 4 -24 -1 -49 -19 -89 -13 -31 -27 -78 -31
-105 -7 -50 -18 -80 -59 -169 -21 -45 -52 -192 -43 -201 5 -5 72 146 96 216
10 30 37 96 60 146 22 51 41 97 41 104 0 7 13 31 28 54 15 22 47 80 71 129 24
48 47 87 52 87 5 0 13 10 18 23 5 13 31 52 58 88 27 35 57 77 67 93 10 17 33
45 49 63 17 19 38 44 47 56 17 25 330 339 375 377 138 117 326 260 342 260 7
0 17 9 23 20 6 11 22 23 35 26 14 3 25 10 25 15 0 5 4 9 8 9 4 0 55 31 113 69
57 37 158 96 224 131 66 34 127 68 136 76 8 8 22 14 30 14 8 0 25 9 39 20 14
11 29 20 34 20 10 0 105 42 156 69 19 10 60 24 90 31 30 7 72 21 92 31 21 11
43 19 50 19 7 0 42 11 78 24 138 50 277 85 460 115 30 5 92 17 138 26 46 9 92
15 104 12 11 -3 32 -1 47 5 14 5 42 11 61 12 19 2 62 6 95 10 59 6 58 6 -50
-32 -306 -107 -571 -238 -800 -396 -44 -30 -96 -64 -115 -75 -71 -41 -265
-188 -262 -200 1 -7 -2 -10 -7 -7 -12 8 -41 -3 -41 -16 0 -5 9 -6 20 -3 11 3
20 2 20 -3 0 -6 -12 -9 -27 -7 -26 3 -43 -7 -118 -68 -5 -4 -19 -15 -30 -23
-11 -9 -3 -7 18 5 20 11 45 17 54 14 11 -4 14 -3 9 6 -5 7 0 18 10 26 23 17
34 19 34 5 0 -5 -7 -10 -15 -10 -9 0 -15 -9 -15 -25 0 -19 -4 -23 -17 -18 -10
4 -14 2 -10 -4 4 -6 0 -16 -9 -23 -8 -7 -13 -19 -10 -27 4 -8 -2 -13 -16 -13
-18 0 -20 -4 -14 -31 5 -25 3 -30 -9 -25 -9 3 -15 0 -15 -10 0 -27 -45 -93
-65 -96 -11 -2 -20 -10 -20 -18 0 -8 -6 -20 -14 -26 -9 -7 -11 -16 -6 -25 5
-8 -2 -5 -14 6 l-22 19 6 -25 c4 -16 1 -31 -7 -40 -7 -8 -25 -33 -41 -56 -15
-24 -34 -43 -42 -43 -8 0 -15 -7 -15 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -4 -10
-10 0 -15 -33 -50 -42 -44 -4 3 -5 -2 -2 -10 4 -11 0 -13 -18 -9 -21 6 -22 5
-9 -10 8 -10 9 -17 3 -17 -6 0 -19 -13 -28 -29 -10 -17 -21 -28 -26 -25 -4 3
-8 1 -8 -3 0 -17 -53 -73 -63 -66 -5 3 -7 2 -4 -4 8 -13 -42 -75 -55 -67 -4 3
-7 0 -6 -8 2 -7 -3 -12 -10 -10 -8 1 -12 -3 -10 -10 6 -16 -30 -50 -44 -42 -7
4 -8 3 -4 -4 8 -14 -23 -64 -35 -56 -5 3 -6 -3 -2 -12 4 -11 3 -15 -4 -11 -14
9 -43 -2 -43 -15 0 -6 7 -5 15 2 13 11 14 10 9 -4 -4 -11 -13 -15 -27 -10 -16
5 -19 3 -14 -10 6 -14 3 -15 -13 -6 -14 7 -20 7 -20 0 0 -5 4 -10 10 -10 5 0
7 -7 3 -16 -3 -8 -12 -13 -20 -9 -11 4 -14 0 -10 -14 5 -22 -17 -60 -29 -48
-4 4 -2 7 4 7 7 0 12 7 12 15 0 8 -4 15 -10 15 -10 0 -14 -21 -11 -47 1 -7 -5
-13 -14 -13 -8 0 -15 5 -15 12 0 9 -3 9 -12 0 -10 -10 -10 -15 1 -21 8 -5 10
-14 6 -21 -6 -9 -10 -9 -17 1 -7 10 -8 8 -5 -9 4 -17 -5 -34 -36 -68 -22 -24
-46 -44 -53 -44 -7 0 -16 -9 -19 -20 -4 -11 -11 -18 -16 -15 -8 6 -14 -16 -10
-38 1 -4 -7 -3 -18 2 -21 12 -26 61 -5 61 7 0 14 10 16 23 2 12 9 21 16 19 6
-1 12 6 12 15 0 16 70 129 88 143 6 4 68 95 97 141 11 18 126 168 160 208 11
13 38 45 60 71 22 26 58 69 80 95 116 141 240 280 247 277 4 -1 7 1 6 5 -2 5
6 17 17 29 11 11 38 40 60 65 22 24 52 49 67 56 19 8 26 17 22 28 -4 10 0 15
11 15 9 0 13 5 10 10 -7 11 -89 -41 -103 -66 -5 -8 -22 -24 -38 -35 -16 -10
-25 -19 -21 -19 5 0 -44 -57 -110 -127 -112 -122 -157 -172 -230 -260 -109
-132 -150 -180 -166 -198 -11 -11 -31 -36 -46 -55 -14 -19 -32 -41 -39 -49 -8
-8 -32 -39 -55 -70 -22 -31 -44 -57 -49 -59 -4 -2 -8 -8 -8 -13 0 -11 -111
-162 -122 -167 -5 -2 -8 -8 -8 -13 0 -5 -28 -49 -63 -97 -34 -48 -73 -105 -87
-127 -13 -22 -29 -44 -35 -50 -6 -5 -22 -31 -36 -57 -13 -27 -30 -48 -36 -48
-7 0 -13 -9 -13 -19 0 -11 -6 -26 -13 -33 -8 -7 -16 -18 -18 -24 -13 -34 -84
-142 -90 -138 -4 2 -11 -1 -15 -7 -4 -8 -3 -9 4 -5 20 12 14 -8 -13 -44 -14
-19 -25 -40 -25 -47 0 -7 -4 -13 -10 -13 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10
-15 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -8 -20 -5 0 -14 -16 -22 -35 -7 -19 -17
-32 -21 -30 -4 3 -14 -15 -23 -40 -15 -47 -36 -75 -55 -75 -6 0 -11 -13 -11
-30 0 -36 -15 -40 -24 -5 -9 35 -56 36 -56 1 0 -13 -7 -29 -15 -36 -13 -10
-15 -9 -15 9 0 11 -5 21 -11 21 -6 0 -20 11 -31 25 -14 18 -26 24 -39 20 -10
-4 -50 -3 -89 1 -59 6 -70 9 -70 25 0 16 -8 19 -56 19 -47 0 -55 3 -50 16 3 8
2 12 -4 9 -6 -3 -13 -24 -16 -46 -8 -47 -16 -54 -44 -37 -27 17 -25 24 8 42
15 8 24 15 19 16 -18 1 27 50 47 51 12 0 15 3 8 6 -10 4 -9 10 5 26 10 12 -6
1 -35 -23 -31 -26 -51 -49 -47 -57 2 -8 0 -11 -5 -8 -17 11 -81 -60 -74 -82 4
-12 3 -14 -3 -5 -7 9 -20 0 -50 -35 -81 -94 -102 -125 -147 -216 -130 -261
-112 -463 61 -710 271 -388 688 -759 1128 -1002 11 -6 47 -27 80 -45 354 -198
751 -341 1125 -405 167 -29 299 -42 525 -51 88 -3 162 -8 164 -10 2 -2 -30 -5
-72 -5 -42 -1 -80 -2 -84 -3 -33 -8 -53 -20 -53 -32 0 -19 20 -18 36 4 12 15
13 15 14 1 0 -13 14 -19 58 -25 57 -8 411 -7 487 1 116 13 231 33 260 45 33
15 98 30 128 30 15 0 27 4 27 8 0 4 21 13 48 21 26 7 72 21 103 32 31 10 61
19 68 19 6 0 34 11 63 25 28 14 58 25 65 25 7 0 13 4 13 9 0 5 19 16 43 24 23
8 41 19 40 25 -3 11 38 32 142 75 52 22 105 46 313 144 76 36 14 18 -144 -41
-239 -90 -336 -119 -524 -160 -319 -68 -566 -90 -934 -83 -567 11 -952 89
-1435 291 -384 161 -763 434 -932 670 -47 67 -61 94 -38 81 7 -5 10 25 9 92
-1 54 1 130 5 168 7 62 16 106 56 268 5 22 18 55 28 75 10 21 26 66 35 102 23
83 141 338 226 485 39 69 56 104 78 158 7 15 16 27 22 27 5 0 10 5 10 10 0 6
19 37 43 69 23 33 50 72 59 88 30 49 43 68 49 73 4 3 43 61 89 130 81 123 124
182 167 230 13 14 23 29 23 35 0 6 9 18 20 28 11 9 20 23 20 31 0 8 7 16 15
20 8 3 15 10 15 15 0 22 52 85 143 174 51 50 107 112 122 137 16 25 76 100
134 167 l105 123 23 -22 c31 -29 193 -327 414 -763 l106 -210 134 -5 c74 -3
136 -4 138 -3 2 1 -6 17 -17 35 -31 51 -545 1076 -570 1137 -21 50 -21 78 -2
66 5 -3 11 4 15 15 4 11 11 18 16 15 5 -4 9 -1 9 4 0 6 8 19 18 30 9 10 55 58
102 107 47 49 117 125 155 168 108 120 228 242 248 249 9 3 17 13 17 21 0 8
20 27 45 43 24 15 44 32 45 37 1 24 154 155 270 231 25 16 82 58 127 92 46 35
84 63 87 63 2 0 59 32 127 72 132 76 147 84 279 148 171 84 275 111 483 126
54 4 96 9 94 11 -3 2 -60 10 -128 19 -138 17 -530 24 -734 13z m195 -99 c-8
-5 -22 -10 -30 -10 -13 0 -13 1 0 10 8 5 22 10 30 10 13 0 13 -1 0 -10z
m-1343 -776 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1174
-1377 c-5 -8 -8 -8 -11 1 -5 17 -22 15 -22 -4 0 -8 -4 -12 -10 -9 -6 4 -5 13
2 25 9 14 16 16 30 9 11 -6 15 -15 11 -22z m-33 -38 c0 -14 -17 -10 -23 5 -4
10 -1 13 9 9 7 -3 14 -9 14 -14z m-112 -154 c-3 -3 -9 2 -12 12 -6 14 -5 15 5
6 7 -7 10 -15 7 -18z m-154 -220 c-4 -8 -10 -15 -15 -15 -5 0 -9 -9 -9 -20 0
-11 -5 -20 -11 -20 -5 0 -9 3 -7 8 22 45 33 62 39 62 5 0 6 -7 3 -15z m-764
-375 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m0 -60 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"/>
<path d="M6888 7393 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7055 7240 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7260 7220 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7600 7070 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3672 6918 c4 -79 8 -424 10 -768 4 -726 -7 -655 123 -787 118 -120
121 -120 408 -128 142 -4 237 -3 237 2 0 5 -15 16 -32 25 -18 9 -62 40 -98 68
-117 95 -190 137 -287 166 l-93 28 -2 763 -3 764 -135 5 -135 5 7 -143z"/>
<path d="M4407 6894 l-117 -96 5 -536 c3 -296 8 -550 11 -566 5 -37 96 -156
186 -243 38 -37 82 -80 97 -95 39 -38 160 -128 172 -128 6 0 8 64 7 166 l-3
167 -62 41 c-33 22 -83 55 -110 71 l-48 30 -3 643 c-1 353 -5 642 -10 642 -4
0 -60 -44 -125 -96z"/>
<path d="M5100 6901 c-25 -16 -76 -55 -113 -86 l-69 -57 7 -752 c4 -413 9
-754 12 -758 2 -5 53 -8 113 -8 l109 0 -2 308 c-2 345 -5 332 76 419 69 75
103 88 237 88 109 0 118 -2 169 -30 98 -54 158 -147 172 -268 4 -40 8 -170 9
-290 0 -165 3 -219 13 -222 6 -3 62 -6 122 -7 l110 -3 0 305 c0 288 -1 309
-22 376 -28 87 -83 172 -160 245 -104 99 -212 135 -383 127 -108 -6 -201 -37
-283 -94 -26 -19 -49 -34 -52 -34 -3 0 -6 127 -6 283 -1 155 -4 328 -8 385
l-6 103 -45 -30z"/>
<path d="M2764 6788 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M2890 6740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2860 6680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7332 5630 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6364 5481 c-108 -49 -114 -169 -11 -245 39 -29 126 -28 164 0 159
118 28 327 -153 245z"/>
<path d="M7352 5450 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M7363 5310 c0 -47 2 -66 4 -42 2 23 2 61 0 85 -2 23 -4 4 -4 -43z"/>
<path d="M7372 5090 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1015 5070 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M980 5040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3608 4963 l-38 -4 0 -108 c0 -59 -3 -111 -6 -114 -4 -4 -21 4 -38
16 -28 20 -43 22 -141 22 -121 0 -151 -10 -213 -69 -67 -62 -99 -164 -88 -278
20 -198 129 -281 327 -248 67 11 78 16 115 55 l41 42 9 -41 9 -41 73 -3 72 -3
-2 388 -3 388 -40 1 c-22 1 -57 -1 -77 -3z m-113 -333 c64 -14 75 -37 75 -146
l0 -94 -47 -30 c-61 -39 -116 -54 -172 -46 -80 11 -119 73 -106 171 15 123
109 178 250 145z"/>
<path d="M2296 4588 c-102 -205 -186 -379 -186 -386 0 -10 19 -12 78 -10 l77
3 17 35 c9 19 24 54 33 77 10 23 24 44 31 47 8 3 99 3 203 -1 l189 -6 23 -51
c47 -105 49 -106 145 -106 46 0 84 2 84 3 0 2 -40 87 -89 188 -122 254 -166
348 -218 464 -25 55 -50 103 -57 107 -6 4 -41 8 -77 8 l-66 0 -187 -372z m325
31 c27 -60 49 -113 49 -119 0 -13 -247 -14 -254 -1 -4 5 24 71 61 146 77 157
60 160 144 -26z"/>
<path d="M1360 4879 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3990 4793 c0 -3 23 -27 50 -53 l51 -47 93 -5 c112 -6 163 -27 188
-78 22 -42 13 -53 -32 -40 -53 16 -248 16 -295 0 -90 -30 -135 -93 -135 -190
0 -30 8 -68 20 -93 36 -77 100 -108 221 -108 80 0 130 15 186 58 34 26 38 24
45 -22 3 -18 12 -20 76 -23 l72 -3 0 188 c0 212 -10 301 -37 337 -26 36 -79
63 -147 75 -64 12 -356 15 -356 4z m348 -334 c30 -10 33 -14 30 -47 -3 -40 -4
-41 -117 -89 -84 -36 -126 -35 -157 1 -42 49 -24 113 40 142 34 16 144 12 204
-7z"/>
<path d="M6067 4786 c-73 -27 -107 -80 -107 -169 0 -44 5 -62 29 -94 49 -67
97 -84 287 -98 98 -8 170 -18 179 -25 21 -18 19 -56 -5 -80 -19 -19 -33 -20
-230 -20 l-210 0 0 -54 0 -55 43 -6 c23 -4 139 -5 257 -3 237 3 254 7 302 72
32 44 37 130 10 182 -43 83 -87 102 -264 109 -73 4 -148 8 -166 11 -43 7 -72
36 -72 74 0 47 28 53 270 59 l215 6 0 50 0 50 -250 2 c-191 2 -259 -1 -288
-11z"/>
<path d="M4736 4729 c-6 -58 -9 -529 -3 -529 53 -8 122 -11 141 -6 l25 6 3
189 c3 187 3 189 28 210 36 31 84 51 124 51 89 0 98 -24 104 -267 l5 -193 83
0 84 0 0 173 c0 194 8 223 69 264 38 25 112 31 154 12 42 -19 49 -52 55 -256
l5 -193 78 0 79 0 0 168 c0 280 -22 355 -119 405 -103 52 -228 32 -315 -51
-30 -28 -40 -34 -44 -22 -11 30 -69 78 -107 89 -93 25 -220 1 -279 -54 -15
-14 -28 -25 -30 -25 -2 0 -1 20 2 45 l5 45 -70 0 -70 0 -7 -61z"/>
<path d="M7353 4630 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3893 3959 c-40 -12 -83 -62 -83 -98 0 -40 31 -74 105 -116 72 -40
83 -66 44 -101 -17 -15 -34 -19 -85 -17 -57 2 -64 0 -64 -17 0 -28 37 -43 101
-43 72 0 118 25 137 75 21 55 -5 102 -80 145 -72 42 -86 65 -63 100 16 24 21
25 70 19 81 -9 99 27 26 52 -39 14 -63 14 -108 1z"/>
<path d="M2127 3953 c-4 -5 -7 -93 -7 -194 l-2 -184 79 -3 c93 -3 153 21 191
78 21 31 23 45 20 115 -4 94 -25 133 -91 167 -45 23 -180 38 -190 21z m125
-63 c55 -16 88 -60 88 -121 0 -59 -21 -113 -48 -128 -12 -6 -39 -11 -61 -11
-35 0 -42 4 -47 24 -4 18 0 210 6 244 0 5 26 1 62 -8z"/>
<path d="M5390 3928 c0 -43 3 -46 39 -40 22 3 30 10 34 30 3 15 5 28 4 29 -1
0 -19 4 -39 8 -38 7 -38 7 -38 -27z"/>
<path d="M2944 3895 c-3 -20 -15 -40 -26 -46 -26 -14 -20 -53 10 -57 21 -3 22
-7 22 -107 0 -93 2 -105 19 -115 34 -18 71 -12 80 13 11 27 6 37 -20 37 -17 0
-19 8 -19 85 0 77 2 85 19 85 21 0 33 22 25 45 -4 8 -14 15 -24 15 -14 0 -20
9 -22 38 -3 32 -7 37 -30 40 -24 3 -28 -1 -34 -33z"/>
<path d="M6457 3850 c-44 -14 -57 -29 -57 -69 0 -41 21 -66 77 -95 40 -20 48
-33 32 -52 -10 -12 -24 -15 -60 -10 -51 7 -61 1 -53 -31 11 -41 123 -39 169 3
49 45 24 113 -54 149 -28 12 -41 24 -41 38 0 18 5 19 49 14 36 -4 51 -2 54 8
14 35 -58 63 -116 45z"/>
<path d="M2560 3830 c0 -14 9 -21 38 -25 61 -10 82 -22 82 -48 0 -20 -4 -22
-38 -19 -56 5 -102 -35 -102 -88 0 -30 6 -44 31 -64 32 -27 63 -34 74 -16 4 7
24 8 50 4 l44 -6 7 37 c8 45 -2 165 -17 198 -15 33 -49 47 -114 47 -48 0 -55
-3 -55 -20z m106 -152 c-3 -13 -6 -33 -6 -45 0 -27 -34 -32 -44 -7 -17 46 -4
71 38 73 14 1 17 -4 12 -21z"/>
<path d="M3222 3829 c2 -20 10 -25 43 -29 50 -7 65 -16 65 -39 0 -14 -10 -20
-47 -24 -58 -6 -93 -41 -93 -93 0 -54 62 -99 107 -78 10 4 36 8 58 8 l40 1 3
107 c2 100 1 108 -21 134 -21 24 -33 28 -92 32 -65 4 -67 4 -63 -19z m98 -167
c0 -39 -14 -55 -39 -47 -28 9 -28 49 1 69 27 19 38 12 38 -22z"/>
<path d="M4285 3830 c-48 -24 -73 -62 -80 -122 -11 -87 47 -138 156 -138 67 0
69 1 69 25 0 24 -3 25 -58 25 -44 0 -63 5 -75 18 -10 10 -17 23 -17 28 0 5 37
9 82 9 80 0 83 1 86 25 6 36 -14 93 -40 117 -35 32 -77 36 -123 13z m79 -62
c24 -33 20 -38 -29 -38 -49 0 -56 11 -28 42 21 24 39 23 57 -4z"/>
<path d="M4710 3840 c-8 -5 -32 -7 -53 -3 l-38 6 3 -134 3 -134 30 0 30 0 5
100 5 100 35 17 c20 10 35 25 35 35 0 20 -32 28 -55 13z"/>
<path d="M5173 3842 c-16 -2 -31 -21 -53 -68 -16 -35 -30 -69 -30 -74 0 -27
-19 -3 -53 65 -31 63 -41 75 -61 75 -14 0 -27 -3 -29 -7 -4 -8 63 -169 96
-230 21 -39 29 -40 56 -12 27 29 116 238 105 248 -5 4 -19 5 -31 3z"/>
<path d="M5400 3710 l0 -140 30 0 30 0 0 128 c0 132 -5 152 -42 152 -16 0 -18
-12 -18 -140z"/>
<path d="M5703 3829 c-113 -56 -102 -222 17 -256 27 -8 63 -11 88 -7 39 6 43
9 40 32 -3 25 -6 26 -48 24 -62 -4 -100 25 -100 74 0 20 5 44 10 55 16 28 68
52 102 45 36 -7 51 20 23 40 -27 20 -84 17 -132 -7z"/>
<path d="M6044 3806 c-32 -32 -34 -38 -34 -100 0 -58 3 -69 28 -97 31 -35 94
-52 152 -42 69 12 62 53 -10 53 -33 0 -55 6 -74 21 -15 12 -25 24 -22 26 3 3
40 6 83 7 l78 1 3 37 c4 47 -21 102 -54 117 -14 6 -45 11 -70 11 -39 0 -51 -5
-80 -34z m108 -33 c30 -27 22 -43 -20 -43 -50 0 -57 8 -30 37 26 27 26 28 50
6z"/>
<path d="M7056 3554 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"/>
<path d="M7006 3458 c-18 -36 -19 -41 -7 -34 9 6 33 66 26 66 -2 0 -11 -14
-19 -32z"/>
<path d="M6852 3170 c-8 -13 -8 -20 -2 -20 6 0 13 7 17 15 9 24 -1 27 -15 5z"/>
<path d="M7048 1673 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
